import { createContext, useEffect, useReducer, useMemo, useContext } from "react";
import { withRouter } from "react-router-dom";
// Contexts
import { SurveyContext } from "src/contexts/survey.context";
// Reducers
import createSurveyReducer from "src/containers/survey/create/CreateSurvey.reducer";
// Helpers
import { redirect } from "src/helpers/router.helper";
import environmentConfig from "src/configs/environment.config";

export const SurveyCreateContext = createContext({
    createSurveyData: {
        surveyUid: undefined,
        surveyData: undefined,
        checkedUrl: undefined,
        groupPanelist: undefined,
        total: 0,
    },
    setSurveyData: () => { },
    checkUrl: () => { },
    setGroup: () => { },
    setSurveyUid: () => { },
});

const { reducer, defaultValue, TYPES } = createSurveyReducer;

export const CreateSurveyProvider = withRouter(({ children, location }) => {
    const { surveyType, surveyStatus } = useContext(SurveyContext);
    const [createSurveyData, dispatchCreateSurvey] = useReducer(reducer, defaultValue);

    useEffect(() => {
        const handleRedirect = (location) => {
            if (surveyType === undefined || surveyStatus === undefined) {
                redirect(environmentConfig.DEFAULT_PAGE_AFTER_AUTH);
            } else if (location.pathname === "/survey/create/step2") {
                redirect("/survey/create/step1");
            }
        };
        handleRedirect(location);
        //eslint-disable-next-line 
    }, []);

    const SurveyCreateContextValue = useMemo(() => {
        return {
            ...createSurveyData,
            setSurveyData: (payload) => {
                dispatchCreateSurvey({
                    type: TYPES.SET_DATA,
                    payload
                });
            },
            checkUrl: (payload) => {
                dispatchCreateSurvey({
                    type: TYPES.CHECK_URL,
                    payload
                });
            },
            setGroup: (payload) => {
                dispatchCreateSurvey({
                    type: TYPES.SET_GROUP,
                    payload
                });
            },
            setSurveyUid: (payload) => {
                dispatchCreateSurvey({
                    type: TYPES.SET_SURVEY_UID,
                    payload
                });
            },
        };
    }, [createSurveyData, dispatchCreateSurvey]);

    return (
        <SurveyCreateContext.Provider value={SurveyCreateContextValue}>
            {children}
        </SurveyCreateContext.Provider>
    );
});

export default SurveyCreateContext;