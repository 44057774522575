import { apiInstance, authorizeInstance } from 'src/configs/axios.config';

const MODULE = "authenticate";

export const getProfile = (configs = {}) => apiInstance.get(`/v1/${MODULE}/profile`, configs);

export const changePassword = (data, configs = {}) => apiInstance.put(`/v1/${MODULE}/password/change`, data, configs);

export const register = (data, configs = {}) => apiInstance.post(`/v1/${MODULE}/register`, data, configs);

export const sendResetPasswordEmail = (data, configs = {}) => authorizeInstance.post(`agent/v1/${MODULE}/forgot_password`, data, configs);

export const validateResetPasswordToken = (data, configs = {}) => authorizeInstance.post(`agent/v1/${MODULE}/validate/forget_password`, data, configs);

export const resetPassword = (data, configs = {}) => authorizeInstance.put(`agent/v1/${MODULE}/reset_password`, data, configs);