import axios from 'axios';

// Configs
import environmentConfig from 'src/configs/environment.config';

// Constants
import { REQUEST_ERRORS } from "src/constants";

// Helpers
import { notification } from "src/helpers/notification.helper";
import { redirect } from "src/helpers/router.helper";
import { isIgnore, getErrorObj, transformErrorResponse, transformSuccessResponse, responseParser } from "src/helpers/axios.helper";

const onResponseFulfilled = (response) => {
    return transformSuccessResponse(response);
};

const onResponseRejected = (error) => {
    const isCancelled = axios.isCancel(error);

    if (error === undefined) {
        redirect("/logout");
        return Promise.reject({
            isCancelled,
            payload: {},
            response: undefined
        });
    } else if (isCancelled) {
        return Promise.reject(error);
    }

    const errorResponse = transformErrorResponse(error, isCancelled);

    const { type, message, detail: description } = getErrorObj(error);

    if (errorResponse.error && !isIgnore(error.config.silent, errorResponse.error.code)) {
        notification({ type: "error", message, description });
    }

    if (type === REQUEST_ERRORS.UNAUTHENTICATED_EXCEPTION) {
        redirect("/logout");
    } else if (type === REQUEST_ERRORS.TECHNICAL_EXCEPTION) {
        redirect("/error");
    }

    return Promise.reject(errorResponse);
};

const authorizeInstance = axios.create({
    baseURL: environmentConfig.AUTHORIZE_SERVER,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
    transformResponse: [responseParser]
});

authorizeInstance.interceptors.response.use(onResponseFulfilled, onResponseRejected);

const apiInstance = axios.create({
    baseURL: environmentConfig.API_SERVER,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
    transformResponse: [responseParser]
});

apiInstance.interceptors.response.use(onResponseFulfilled, onResponseRejected);

const apiInstanceNoParse = axios.create({
    baseURL: environmentConfig.API_SERVER,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
});

apiInstanceNoParse.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export {
    authorizeInstance,
    apiInstance,
    apiInstanceNoParse
};