import React, { useMemo, useReducer } from 'react';
import { ConfigProvider } from "antd";
import { IntlProvider } from 'react-intl';
import localeEn from 'antd/es/locale/en_US';
import localeTh from 'antd/es/locale/th_TH';

// Contexts
import { Locale } from "src/contexts/security.context";

// Helpers
import { setLocale } from "src/helpers/locale.helper";

// Reducers
import localeReducer from "src/containers/app/locale.reducer";

// Components
import RouterComponent from 'src/components/router/Router';

// i18n
import message_en from 'src/i18n/en.json';
import message_th from 'src/i18n/th.json';

const getConfigLocale = (localeValue) => {
    const config = {
        en: {
            ...localeEn,
            Calendar: {
                ...localeEn.Calendar,
                lang: {
                    ...localeEn.Calendar.lang,
                    monthFormat: "MMMM",
                }
            },
            DatePicker: {
                ...localeEn.DatePicker,
                lang: {
                    ...localeEn.DatePicker.lang,
                    monthFormat: "MMMM",
                }
            }
        },
        th: {
            ...localeTh,
            Calendar: {
                ...localeTh.Calendar,
                lang: {
                    ...localeTh.Calendar.lang,
                    monthFormat: "MMMM",
                }
            },
            DatePicker: {
                ...localeTh.DatePicker,
                lang: {
                    ...localeTh.DatePicker.lang,
                    monthFormat: "MMMM",
                }
            }
        }
    };

    return config[localeValue];
};

const getMessages = (localeValue) => {
    const messages = {
        en: message_en,
        th: message_th
    };

    return messages[localeValue];
};

const AppPage = () => {
    const [locale, dispatch] = useReducer(localeReducer.reducer, localeReducer.defaultValue);
    const { value: localeValue } = locale;

    const localeContextValue = useMemo(() => {
        return {
            value: locale,
            setLocale: (payload) => {
                setLocale(payload.value);
                return dispatch({ type: localeReducer.TYPES.CHANGE_LOCALE, payload });
            },
        };
    }, [locale, dispatch]);

    return (
        <ConfigProvider locale={getConfigLocale(localeValue)}>
            <IntlProvider key={localeValue} locale={localeValue} messages={getMessages(localeValue)}>
                <Locale.Provider value={localeContextValue}>
                    <RouterComponent />
                </Locale.Provider>
            </IntlProvider>
        </ConfigProvider>
    );
};

export default AppPage;