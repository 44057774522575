// APIs
import * as AuthenticateAPI from "src/apis/authenticate.api";
import * as AuthorizeAPI from "src/apis/authorize.api";

// Configs
import { apiInstance } from "src/configs/axios.config";

// Constants
import { AH_ACCESS_TOKEN, AXIOS_SILENT_ALL, USER_TYPE_AGENT } from "src/constants";

// Helpers
import { getLocaleByKey } from "src/helpers/locale.helper";
import { setInstanceToken } from "src/helpers/axios.helper";

export const login = async ({ username, password, permanent, email }) => {
    const { payload, success: loginSuccess } = await AuthorizeAPI.login({ username, password, permanent, email, user_type: USER_TYPE_AGENT }).catch(err => err);

    if (!loginSuccess) {
        return undefined;
    }

    const { token, tokenExpired } = payload;
    const headers = { [AH_ACCESS_TOKEN]: token };

    const { payload: userProfile, success: profileSuccess } = await AuthenticateAPI.getProfile({ headers }).catch(err => err);
    if (!profileSuccess) {
        return undefined;
    }

    setInstanceToken(apiInstance, token);

    return { userProfile, token, tokenExpired };
};

export const requestNewToken = async () => {
    const { payload, success } = await AuthorizeAPI.getNewToken().catch(err => err);

    if (!success) {
        return {};
    }

    const { token, tokenExpired } = payload;
    setInstanceToken(apiInstance, token);

    return { token, tokenExpired };
};

export const initialAuth = async () => {
    const { payload, success: getTokenSuccess } = await AuthorizeAPI.getNewToken({ silent: AXIOS_SILENT_ALL }).catch(err => err);

    if (!getTokenSuccess) {
        return undefined;
    }

    const { token, tokenExpired } = payload;
    const headers = { [AH_ACCESS_TOKEN]: token };

    const { payload: userProfile, success: profileSuccess } = await AuthenticateAPI.getProfile({ headers }).catch(err => err);
    if (!profileSuccess) {
        return undefined;
    }

    setInstanceToken(apiInstance, token);

    return { userProfile, token, tokenExpired, locale: getLocaleByKey(userProfile.locale) };
};

export const sendResetPasswordEmail = async (params) => {
    try {
        await AuthenticateAPI.sendResetPasswordEmail(params);
        return true;
    } catch (error) {
        return false;
    }
};

export const validateResetPasswordToken = async (token) => {
    return AuthenticateAPI.validateResetPasswordToken({ token }, { silent: [AXIOS_SILENT_ALL] }).catch(err => err);
};

export const resetPassword = async ({ params, token }) => {
    const data = {
        "confirm_new_password": params.confirm,
        "new_password": params.password,
        token
    };
    return await AuthenticateAPI.resetPassword(data).catch(err => err);
};