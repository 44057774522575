const TYPES = {
    SET_CRITERIA: "SET_CRITERIA",
};
const defaultValue = {
    pagination: {
        current: 1,
        pageSize: 10
    },
    surveyType: undefined,
    surveyStatus: undefined,
};
const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_CRITERIA: {
            const { type, status, current, pageSize } = action.payload;
            return {
                ...state,
                surveyType: type,
                surveyStatus: status,
                pagination: {
                    current,
                    pageSize
                },
            };
        }
        default:
            return { ...state };
    }
};
const surveyReducer = {
    TYPES,
    defaultValue,
    reducer
};
export default surveyReducer;