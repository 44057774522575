import QueryString from "query-string";

export const randomCharacter = (length) => {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let result = '';
    for (let i = length; i > 0; --i) {
        result += characters[Math.floor(Math.random() * characters.length)];
    }
    return result;
};

export const randomString = (length) => {
    const characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    let result = '';
    for (let i = length; i > 0; --i) {
        result += characters[Math.floor(Math.random() * characters.length)];
    }
    return result;
};

export const getParams = (urlParams) => {
    return QueryString.parse(urlParams);
};

export const naturalSorter = (as, bs) => {
    let a, b, a1, b1, i = 0, n, L,
        rx = /(\.\d+)|(\d+(\.\d+)?)|([^\d.]+)|(\.\D+)|(\.$)/g;  /** Todo : แก้จาก regex ให้เป็น code ปกติ */
    if (as === bs) return 0;
    a = as.toLowerCase().match(rx);
    b = bs.toLowerCase().match(rx);
    L = a.length;
    while (i < L) {
        if (!b[i]) return 1;
        a1 = a[i];
        b1 = b[i++];
        if (a1 !== b1) {
            n = a1 - b1;
            if (!isNaN(n)) return n;
            return a1 > b1 ? 1 : -1;
        }
    }
    return b[i] ? -1 : 0;
};

export const messageToOpener = (message, target) => {
    if (window.opener && typeof window.opener.postMessage === 'function') {
        window.opener.postMessage(message, target);
    }
};

export const saveFile = (data, fileName, type = undefined) => {
    let blob;
    if (type === "base64") {
        const decodeData = Buffer.from(data, "base64");
        blob = new Blob([decodeData]);
    } else {
        blob = new Blob([data]);
    }
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', fileName);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
};

export const urlExists = async (url) => {
    try {
        const response = await fetch(url, { method: 'GET', mode: "no-cors" });
        if (response.status === 200 || response.status === 0) {
            return true;
        }
    } catch (error) {
        console.error("There has been a problem with url:", url, error);
    }
};

export const openWithNewTab = (url) => {
    window.open(url, "_blank");
};

export const rowsToCSV = (rows, fileName) => {
    const csvContent = rows.map(e => `${e}\n`);
    var encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent.join(""));
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link);
    link.click();
};

export const transformTrim = (text) => {
    if (text) {
        return text.trim();
    }
    return;
};