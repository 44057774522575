export const LOCALE = {
    EN: { value: 'en', text: "English" },
    TH: { value: 'th', text: "ไทย" }
};

// BUSINESS
export const MAX_POINT_EARNED = 1000000;
export const MAX_QUOTA = 1000000;


// API HEADERS
export const AH_ACCESS_TOKEN = "token";
export const AH_LOCALE = "locale";

// LOCAL STORAGE
export const LS_LOCALE = "locale";

// AXIOS
export const AXIOS_SILENT_ALL = "ALL";

export const REQUEST_ERRORS = {
    ENDPOINT_NOT_FOUND: "ENDPOINT_NOT_FOUND",
    BUSINESS_EXCEPTION: "BUSINESS_EXCEPTION",
    TECHNICAL_EXCEPTION: "TECHNICAL_EXCEPTION",
    UNAUTHENTICATED_EXCEPTION: "UNAUTHENTICATED_EXCEPTION",
    SERVICE_DOWN: "SERVICE_DOWN",
    SESSION_EXIPRED: "SESSION_EXIPRED",
    NETWORK_ERROR: "NETWORK_ERROR"
};

// SCREENS
export const SCREEN_SIZES = {
    XS: '576px'
};

/* REGEX */
export const PASSWORD_WHITESPACE_REGEX = /^[^\s]+(\s+[^\s]+)*$/;
export const PASSWORD_REGEX = /^[a-zA-Z0-9!@#$%^&*]{8,}$/;
export const PASSWORD_REGEX_STRONG = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})$/;
export const REGEX_VALID_URL = /^(http(s)?:\/\/)?(www.)?([a-zA-Z0-9])+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/[^\s]*)?$/;
export const DIGIT_REGEX = /^\d+$/;

// ENUM
export const SURVEY_TYPE = {
    ONLINE_SURVEY: "online_survey",
    OTHER_JOBS: "other_jobs"
};
export const USER_TYPE_AGENT = "agent";
export const SURVEY_STATUS = {
    ALL: "all",
    START: "start",
    RUNNING: "running",
    CLOSED: "closed",
    POINT_ISSUED: "point_issued",
};
export const REWARD_STATUS = {
    AVAILABLE: "available",
    PENDING: "pending",
    CLOSED: "closed",
};
export const REDEMPTION_STATUS = {
    REDEEMING: "redeeming",
    ISSUED: "issued",
    REJECTED: "rejected",
};
export const REWARD_TYPE = {
    TRUE_MONEY: "true_money",
    BANK_ACCOUNT: "bank_account",
    PROMPT_PAY: "prompt_pay",
};
// LINK
export const EMAIL_FILE_MANUAL_LINK = "https://docs.google.com/document/d/1AlQkP1NUEz6bnX5JSrxh2wEJh6jqkMBF/edit";

export const CHAR_LENGTH = 5.8;
