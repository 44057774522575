/* eslint-disable */
import { LOCALE, SURVEY_TYPE, SURVEY_STATUS } from 'src/constants';

const {
    API_RESOURCE = process.env.REACT_APP_API_RESOURCE,
    API_SERVER = process.env.REACT_APP_API_SERVER,
    AUTHORIZE_SERVER = process.env.REACT_APP_AUTHORIZE_SERVER
} = window.globalConfig || {};

export default {
    API_RESOURCE,
    API_SERVER,
    AUTHORIZE_SERVER,
    VERSION: process.env.REACT_APP_VERSION,
    TIMEZONE: process.env.REACT_APP_DEFAULT_TIMEZONE,
    NOTIFICATION_DURATION: process.env.REACT_APP_NOTIFICATION_DURATION || 5,
    LOCALE: process.env.REACT_APP_LOCALE || LOCALE.EN,
    DEFAULT_TIMEZONE: process.env.REACT_APP_DEFAULT_TIMEZONE,
    DEFAULT_PAGE_AFTER_AUTH: `/survey/list?type=${SURVEY_TYPE.ONLINE_SURVEY}&status=${SURVEY_STATUS.ALL}`
};