import { createContext, useReducer, useMemo } from "react";
import { withRouter } from "react-router-dom";

import rewardReducer from "src/containers/reward/reward.reducer";

export const RewardContext = createContext({
    pagination: {
        current: 1,
        pageSize: 10,
    },
    status: 'all',
    setCriteria: () => { },
    changeStatus: () => { },
    changePage: () => { },
});

const { reducer, defaultValue, TYPES } = rewardReducer;

export const RewardProvider = withRouter(({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const RewardContextValue = useMemo(() => {
        return {
            ...state,
            setCriteria: (payload) => {
                dispatch({
                    type: TYPES.SET_CRITERIA,
                    payload
                });
            },
            changeStatus: (payload) => {
                dispatch({
                    type: TYPES.CHANGE_STATUS,
                    payload
                });
            },
            changePage: (payload) => {
                dispatch({
                    type: TYPES.CHANGE_PAGE,
                    payload
                });
            }
        };
    }, [state, dispatch]);

    return (
        <RewardContext.Provider value={RewardContextValue}>
            {children}
        </RewardContext.Provider>
    );
});

export default RewardContext;