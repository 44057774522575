const TYPES = {
    SET_CRITERIA: "SET_CRITERIA",
    CHANGE_STATUS: "CHANGE_STATUS",
    CHANGE_PAGE: "CHANGE_PAGE",
};
const defaultValue = {
    pagination: {
        current: 1,
        pageSize: 10,
    },
    status: "all",
};
const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_CRITERIA: {
            const { current, pageSize, status } = action.payload;
            return {
                ...state,
                pagination: {
                    current,
                    pageSize,
                },
                status: status
            };
        }
        case TYPES.CHANGE_STATUS: {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    current: 1,
                },
                status: action.payload
            };
        }
        case TYPES.CHANGE_PAGE: {
            const { current, pageSize } = action.payload;
            return {
                ...state,
                pagination: {
                    current,
                    pageSize,
                },
            };
        }
        default:
            return { ...state };
    }
};
const rewardReducer = {
    TYPES,
    defaultValue,
    reducer
};
export default rewardReducer;