import { createContext, useReducer, useMemo } from "react";
import { withRouter } from "react-router-dom";

import surveyReducer from "src/containers/survey/list/survey.reducer";

export const SurveyContext = createContext({
    pagination: {
        current: 1,
        pageSize: 10
    },
    surveyType: 'online_survey',
    surveyStatus: 'all',
    setCriteria: () => { },
});

const { reducer, defaultValue, TYPES } = surveyReducer;

export const SurveyProvider = withRouter(({ children }) => {
    const [state, dispatch] = useReducer(reducer, defaultValue);

    const SurveyContextValue = useMemo(() => {
        return {
            ...state,
            setCriteria: (payload) => {
                dispatch({
                    type: TYPES.SET_CRITERIA,
                    payload
                });
            }
        };
    }, [state, dispatch]);

    return (
        <SurveyContext.Provider value={SurveyContextValue}>
            {children}
        </SurveyContext.Provider>
    );
});

export default SurveyContext;