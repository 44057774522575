import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

// Configs
import { apiInstance } from "src/configs/axios.config";

// Helpers
import { getTokenExpiredMs } from "src/helpers/security.helper";
import { getLocaleByKey } from "src/helpers/locale.helper";
import { setInstanceToken } from "src/helpers/axios.helper";

// Services
import { initialAuth, requestNewToken } from "src/services/authenticate.service";

// Contexts
import { AuthState, Locale } from "src/contexts/security.context";

// Reducers
import authenticateReducer from "src/containers/app/authenticate.reducer";

// Components
import LoadingScreen from "src/components/shared/loading/LoadingScreen";

const Authenticate = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [authenticate, dispatch] = useReducer(authenticateReducer.reducer, authenticateReducer.defaultValue);
    const { authState, userProfile } = authenticate;
    const { setLocale } = useContext(Locale);
    const exchangeTokenCB = useRef();

    const setLogout = useCallback(() => {
        setInstanceToken(apiInstance, undefined);
        clearTimeout(exchangeTokenCB.current);
        exchangeTokenCB.current = undefined;
        dispatch({ type: authenticateReducer.TYPES.LOGOUT });
    }, [dispatch]);

    const exchangeToken = useCallback(async () => {
        const { token, tokenExpired } = await requestNewToken();

        if (token) {
            exchangeTokenCB.current = setTimeout(exchangeToken, getTokenExpiredMs(tokenExpired));
        } else {
            setLogout();
        }
    }, [setLogout]);

    const authorize = useCallback(({ userProfile, tokenExpired }) => {
        exchangeTokenCB.current = setTimeout(exchangeToken, getTokenExpiredMs(tokenExpired));

        return Promise.all([
            setLocale(getLocaleByKey(userProfile.locale)),
            dispatch({ type: authenticateReducer.TYPES.LOGIN, payload: userProfile })
        ]);
    }, [exchangeToken, dispatch, setLocale]);

    useEffect(() => {
        const asyncInitial = async () => {
            const payload = await initialAuth();

            if (payload === undefined) {
                setLoading(false);
                return;
            }

            const { userProfile, tokenExpired, locale } = payload;
            exchangeTokenCB.current = setTimeout(exchangeToken, getTokenExpiredMs(tokenExpired));
            setLocale(locale);
            await dispatch({ type: authenticateReducer.TYPES.LOGIN, payload: userProfile });
            setLoading(false);
        };

        asyncInitial();
    }, [exchangeToken, dispatch, setLocale]);

    if (loading) {
        return <LoadingScreen />;
    }

    return (
        <AuthState.Provider value={{ authState, userProfile, authorize, setLogout }}>
            {children}
        </AuthState.Provider>
    );
};

export default Authenticate;