import React, { Suspense, Component } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
// Components
import ScrollToTop from "src/components/router/ScrollToTop";
import LoadingScreen from "src/components/shared/loading/LoadingScreen";
// Providers
import { CreateSurveyProvider } from "src/contexts/createSurvey.context";
import { SurveyProvider } from "src/contexts/survey.context";
import { RewardProvider } from "src/contexts/reward.context";
// Configs
import envConfig from "src/configs/environment.config";

const AuthenticatedLayout = React.lazy(() => import("src/components/layout/authenticate/AuthenticatedLayout"));
const SurveyLayout = React.lazy(() => import("src/components/layout/survey/SurveyLayout"));
const RewardLayout = React.lazy(() => import("src/components/layout/reward/RewardLayout"));
const PanelistLayout = React.lazy(() => import("src/components/layout/panelist/PanelistLayout"));
const PageNotFound = React.lazy(() => import("src/containers/error/PageNotFound"));

class Router extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errorUi: false,
        };
    }

    static getDerivedStateFromError() {
        return {
            errorUi: true
        };
    }

    render() {
        const { errorUi } = this.state;

        if (errorUi) {
            return <div>Error</div>;
        }

        const notFound = <Route component={PageNotFound} />;

        return (
            <ScrollToTop>
                <Suspense fallback={<LoadingScreen />}>
                    <Switch>
                        <Route exact path="/login" component={React.lazy(() => import("src/containers/login/LoginPage"))} />
                        <Route exact path="/forgot-password" component={React.lazy(() => import("src/containers/password/ForgotPasswordPage"))} />
                        <Route exact path="/check-email" component={React.lazy(() => import("src/containers/password/CheckEmailPage"))} />
                        <Route exact path="/reset-password" component={React.lazy(() => import("src/containers/password/ResetPasswordPage"))} />
                        <Route exact path="/invalid-reset-link" component={React.lazy(() => import("src/containers/password/ExpiredResetLinkPage"))} />
                        <Route exact path="/finish-reset-password" component={React.lazy(() => import("src/containers/password/FinishChangePasswordPage"))} />
                        <Route exact path="/logout" component={React.lazy(() => import("src/containers/app/LogoutPage"))} />

                        <Route path={["/survey"]}>
                            <SurveyLayout>
                                <Suspense fallback={<LoadingScreen />}>
                                    <SurveyProvider>
                                        <Switch>
                                            <Route
                                                exact
                                                path="/survey/list"
                                                component={React.lazy(() => import("src/containers/survey/list/SurveyListPage"))}
                                            />
                                            <Route
                                                exact
                                                path="/survey/detail/:type/:id"
                                                component={React.lazy(() => import("src/containers/survey/detail/SurveyDetail"))}
                                            />
                                            <CreateSurveyProvider>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/survey/create/step1"
                                                        component={React.lazy(() => import("src/containers/survey/create/CreateStep1Page"))}
                                                    />
                                                    <Route
                                                        exact
                                                        path="/survey/create/step2"
                                                        component={React.lazy(() => import("src/containers/survey/create/CreateStep2Page"))}
                                                    />
                                                    {notFound}
                                                </Switch>
                                            </CreateSurveyProvider>
                                            {notFound}
                                        </Switch>
                                    </SurveyProvider>
                                </Suspense>
                            </SurveyLayout>
                        </Route>

                        <Route path={["/admin", "/dashboard", "/unauthorized", "/email-targeting"]}>
                            <AuthenticatedLayout>
                                <Suspense fallback={<LoadingScreen />}>
                                    <Switch>
                                        <Route
                                            exact
                                            path="/dashboard"
                                            component={React.lazy(() => import("src/containers/dashboard/DashboardPage"))}
                                        />
                                        <Route
                                            exact
                                            path="/admin"
                                            component={React.lazy(() => import("src/containers/admin/AdminPage"))}
                                        />
                                        <Route
                                            exact
                                            path="/unauthorized"
                                            component={React.lazy(() => import("src/containers/error/Unauthorized"))}
                                        />
                                        {notFound}
                                    </Switch>
                                </Suspense>
                            </AuthenticatedLayout>
                        </Route>

                        <Route path={["/reward"]}>
                            <RewardLayout>
                                <Suspense fallback={<LoadingScreen />}>
                                    <RewardProvider>
                                        <Switch>
                                            <Route
                                                exact
                                                path="/reward"
                                                component={React.lazy(() => import("src/containers/reward/list/RewardListPage"))}
                                            />
                                            <Route
                                                exact
                                                path="/reward/create"
                                                component={React.lazy(() => import("src/containers/reward/create/RewardCreatePage"))}
                                            />
                                            <Route
                                                exact
                                                path="/reward/:id"
                                                component={React.lazy(() => import("src/containers/reward/detail/RewardDetailPage"))}
                                            />
                                            {notFound}
                                        </Switch>
                                    </RewardProvider>
                                </Suspense>
                            </RewardLayout>
                        </Route>

                        <Route path={["/panelist"]}>
                            <PanelistLayout>
                                <Suspense fallback={<LoadingScreen />}>
                                    <Switch>
                                        <Route
                                            exact
                                            path="/panelist"
                                            component={React.lazy(() => import("src/containers/panelist/list/PanelistListPage"))}
                                        />
                                        {notFound}
                                    </Switch>
                                </Suspense>
                            </PanelistLayout>
                        </Route>

                        <Redirect exact from="/" to={envConfig.DEFAULT_PAGE_AFTER_AUTH} />
                        {notFound}
                    </Switch>
                </Suspense>
            </ScrollToTop >
        );
    }
}

export default withRouter(Router);