const TYPES = {
    SET_DATA: "SET_DATA",
    CHECK_URL: "CHECK_URL",
    SET_SURVEY_UID: "SET_SURVEY_UID",
    SET_GROUP: "SET_GROUP",
};

const defaultValue = {
    surveyUid: undefined,
    surveyData: undefined,
    checkedUrl: undefined,
    groupPanelist: undefined,
    total: 0,
};

const reducer = (state, action) => {
    switch (action.type) {
        case TYPES.SET_DATA: {
            return {
                ...state,
                surveyData: action.payload,
            };
        }
        case TYPES.CHECK_URL: {
            return {
                ...state,
                checkedUrl: action.payload,
            };
        }
        case TYPES.SET_GROUP: {
            const { groupData, totalPanelist } = action.payload;
            return {
                ...state,
                groupPanelist: groupData,
                total: totalPanelist,
            };
        }
        case TYPES.SET_SURVEY_UID:
            return {
                ...state,
                surveyUid: action.payload
            };
        default:
            return state;
    }
};

const registerReducer = {
    TYPES,
    defaultValue,
    reducer
};

export default registerReducer;